<template>
	<div class="row d-flex justify-content-center">
		<div class="col">
			<vue-slider class="my-3" v-model="model" :lazy="true" :marks="model" :min="0" :max="maxValue + 100"
						:interval="5" :process="process">
				<template v-slot:process="{ start, end, style, index }">
					<div class="vue-slider-process" :style="style">
						<div :class="[
              'merge-tooltip',
              'vue-slider-dot-tooltip-inner',
              'vue-slider-dot-tooltip-inner-top',
            ]">
							{{ value[index] }}-{{ value[index + 1] }}
						</div>
					</div>
				</template>
			</vue-slider>
		</div>
		<div class="col-auto d-flex flex-column">
			<button class="btn btn-outline-primary" @click="model.push(maxValue + 50)">Add group</button>
			<button class="btn btn-sm btn-link text-danger text-small" @click="reset">Reset</button>
		</div>
	</div>
</template>

<script>
	import VueSlider from 'vue-slider-component'
	import 'vue-slider-component/theme/antd.css'

	export default {
		name: "multiple-range",
		props: {
			value: {
				type: Array,
				default: () => []
			}
		},
		methods: {
			reset() {
				this.model = [50, 100, 500]
			},
			process(val) {
				return this.model.slice(0, -1).map((c, i) => [val[i], val[i + 1]])
			}
		},
		computed: {
			maxValue() {
				return this.model.reduce((c, v) => Math.max(c, v), 0)
			},
			model: {
				get() {
					return this.value
				},
				set(val) {

					let x = val.filter(v => v > 0)

					if (x.length < 2) {
						x = [0, ...x]
					}

					this.$emit('input', x)
				}
			}
		},
		components: {
			VueSlider
		}
	}
</script>


<style scoped lang="scss">
	.merge-tooltip {
		position: absolute;
		left: 50%;
		top: -0.3em;
		transform: translate(-50%, -15px);
		background-color: transparent;
		box-shadow: none;
		color: #5e5e5e;
		font-size: 0.4em;

		&:after {
			display: none;
		}
	}
</style>

<style lang="scss">
	@import "@/assets/style/_palette";

	.vue-slider-process {
		background-color: $color-primary;
	}

	.vue-slider-dot-handle {
		border-color: $color-primary;
	}

	.vue-slider-mark-label {
		font-size: 0.5em;
	}

</style>
