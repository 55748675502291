<template>
	<div class="p-2 border">
		<h5>Paylines RTP contribution</h5>
		<table class="table">
			<thead>
			<tr>
				<th>Payline</th>
				<th>Contribution</th>
				<th>Distribution</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(payline, payline_id) in paylines">
				<td v-text="payline_id"></td>
				<td>
					<div>{{ payline | numeral("0,0") }}</div>
					<div class="text-small" :class="{'text-success': (payline - winAverage) > 0, 'text-danger': (payline - winAverage) < 0}">
						({{(payline - winAverage) > 0 ? '+' : ''}}{{ ((payline - winAverage) / payline) | numeral("0.0%") }})
					</div>
				</td>
				<td>
					<div>
						<div>{{ (payline / totalWin) | numeral("0.00%") }}</div>
						<div class="progress">
							<div class="progress-bar" :style="{width: `${(payline / totalWin * 100).toFixed(2)}%`}"></div>
						</div>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
export default {
	name: 'spinner-paylines-rtp-contribution',
	props: {
		paylines: {},
		totalWin: {
			type: Number,
			default: 0
		}
	},
	computed: {
		winAverage() {
			const values = Object.values(this.paylines)
			return values.reduce((c, v) => c + v, 0) / values.length
		}
	}
}
</script>
