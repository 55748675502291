<template>
	<table class="table">
		<thead class="font-weight-bold">
		<tr>
			<td>Source</td>
			<td>Min win</td>
			<td>Max win</td>
			<td>Avg win</td>
			<td>Bet amount</td>
			<td>Win amount</td>
			<td>Frequency</td>
			<td>Counter</td>
			<td>RTP</td>
		</tr>
		</thead>
		<tbody>
		<template v-for="source in sortedSources" v-if="summary">
			<tr>
				<td class="font-weight-bold text-capitalize">{{source.source}} {{source.payline}}</td>
				<td>{{ source.min_win | numeral('0,0') }}</td>
				<td>{{ source.max_win | numeral('0,0') }}</td>
				<td>~ {{(source.total_win_amount / source.win_counter) | numeral('0,0')}}</td>
				<td>{{ source.total_bet_amount | numeral('0,0') }}</td>
				<td>{{ source.total_win_amount | numeral('0,0') }}</td>
				<td>{{(source.frequency_total / source.win_counter) | numeral('0,0.00')}}</td>
				<td>{{ source.win_counter | numeral('0,0') }}</td>
				<td>{{(source.total_win_amount / summary.balance.total_bet) | numeral('0,0.00%')}}</td>
			</tr>
		</template>
		</tbody>
		<tfoot>
		<tr>
			<td></td>
			<td></td>
			<td></td>
			<td>~ {{(summary.balance.total_win / summary.counters.spins) | numeral('0')}}</td>
			<td>{{ summary.balance.total_bet | numeral('0,0') }}</td>
			<td>{{ summary.balance.total_win | numeral('0,0') }}</td>
			<td></td>
			<td></td>
			<td>{{(summary.rtp) | numeral('0,0.00%')}}</td>
		</tr>
		</tfoot>
	</table>
</template>

<script>
	export default {
		props: {
			summary: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		computed: {
			sortedSources() {
				if (this.summary.win_details.sources) {
					let x = this.summary.win_details.sources.slice()
					return x.sort((a, b) => a.total_win_amount > b.total_win_amount ? -1 : 1)
				}
				return []
			}
		}
	}
</script>
