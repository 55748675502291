<template>
	<div class="p-2 border">
		<h5>Paylines Combinations</h5>
		<div class="row">
			<div class="col-12 col-lg-6 col-xl-4" v-for="combination in combinations" :key="combination.symbol">
				<payline-combination-group v-model="model.displayWildDetails" :bet-per-line="betPerLine" class="border bg-white p-3 my-3" :total-spins="totalSpins" :config="config" :combination="combination"/>
			</div>
		</div>
	</div>
</template>

<script>

	import PaylineCombinationGroup from "./payline-combination-group";
	export default {
		name: "spinner-payline-combos",
		components: {PaylineCombinationGroup},
		props: {
			value: {type: Object},
			combinations: {type: Array},
			totalSpins: {type: Number},
			config: {type: Object},
			betPerLine: {type: Number},
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			},
		}
	}
</script>

