<template>
	<div>
		<table class="table table-shrikned">
			<thead>
			<tr>
				<th>MAIN</th>
				<th>Prize</th>
				<th>Amount</th>
				<th>Hits</th>
				<th>Rate</th>
				<th>EV</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(combination, identifier) in computedCombinations">
				<td style="width: 300px">
					{{symbol.name}} [{{identifier.split("-")[0]}}]<span class="font-weight-bold text-danger"
																		v-if="identifier.split('-')[1]"
																		:title="`${identifier.split('-')[1]} WILDS`">[with {{identifier.split("-")[1]}} WILD]</span>
				</td>
				<td>
					<prize-viewer v-if="getComboPrize(identifier)" :display-strategy="false"
								  :prize="getComboPrize(identifier)"></prize-viewer>
				</td>
				<td>
					<template v-if="getComboPrize(identifier)">
						<div class="text-small">{{(betPerLine * combination *
							getComboPrize(identifier).multiplier)|numeral("0")}}
						</div>
						<div class="progress" style="height: 4px;">
							<div class="progress-bar"
								 :style="{width: `${((betPerLine * combination * getComboPrize(identifier).multiplier)/maxAmount*100).toFixed(2)}%`}"></div>
						</div>
					</template>
				</td>
				<td>{{combination|numeral('0')}}</td>
				<td>
					<div class="text-small text-nowrap">1 in {{Math.round(1/(combination/totalSpins))}}</div>
				</td>
				<td>
					<div class="text-small">{{(combination/totalSpins) | numeral(percentageFormat)}}</div>
					<div class="progress" style="height: 4px;">
						<div class="progress-bar"
							 :style="{width: `${(combination/maxOccurrencies*100).toFixed(2)}%`}"></div>
					</div>
				</td>
			</tr>
			</tbody>
		</table>

		<div>
			<label class="bg-light p-3 cursor-pointer w-100" :for="`show-wild-${_uid}`">
				<input class="mr-2" :id="`show-wild-${_uid}`" type="checkbox" v-model="model"> Show wild details</label>
		</div>

	</div>
</template>

<script>
	import PrizeViewer from "../slot-config-editor/prize/prize-viewer";

	export default {
		name: "payline-combination-table",
		components: {PrizeViewer},
		props: {
			combinations: Object,
			totalSpins: Number,
			symbol: Object,
			config: Object,
			betPerLine: Number,
			value: Boolean,
		},
		methods: {
			getComboPrize(identifier) {
				let tmp = identifier.split("-")
				const in_a_row = tmp[0]
				//const wilds = tmp[1]

				const prize = this.config.paytable.multipliers[this.symbol.id][in_a_row]

				if (prize.multiplier > 0) {
					return prize
				}
				return null
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			},
			percentageFormat() {
				return '0.00%'
			},
			computedCombinations() {
				if (this.model) {
					return this.combinations
				} else {

					let result = {}
					for (let identifier in this.combinations) {
						const in_a_row = identifier.split("-")[0]

						if (result[in_a_row] === undefined) {
							result[in_a_row] = this.combinations[identifier];
						} else {
							result[in_a_row] += this.combinations[identifier]
						}


					}

					return result
				}
			},
			maxOccurrencies() {
				return Object.values(this.combinations).reduce((c, combo) => Math.max(c, combo), 0)
			},
			maxAmount() {
				let maxAmount = 0;

				for (let identifier in this.combinations) {
					const combo = this.combinations[identifier]

					const prize = this.getComboPrize(identifier)

					if (prize) {
						const amount = this.betPerLine * combo * prize.multiplier
						maxAmount = Math.max(maxAmount, amount)
					}
				}

				return maxAmount
			},
			totalAmount() {
				let totalAmount = 0;

				for (let identifier in this.combinations) {
					const combo = this.combinations[identifier]

					const prize = this.getComboPrize(identifier)

					if (prize) {
						const amount = this.betPerLine * combo * prize.multiplier
						totalAmount += amount
					}
				}

				return totalAmount
			},
		}
	}
</script>
