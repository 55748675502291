<template>
	<div v-if="model">
		<div class="d-flex">
			<div>
				<input v-model="model.grouped" type="checkbox" :id="`grouped-a-${_uid}`">
				<label class="ml-2"
					   :for="`grouped-a-${_uid}`">Group prizes</label>
			</div>
			<div class="ml-3">
				<input v-model="model.showAmount" type="checkbox" :id="`amount-a-${_uid}`">
				<label class="ml-2"
					   :for="`amount-a-${_uid}`">Compare amounts</label>
			</div>
		</div>
		<multiple-range v-if="model.grouped" v-model="model.groups"></multiple-range>
		<div class="d-flex align-items-center" v-else>
			<div class="mr-2">Round amounts for</div>
			<multiplier v-model="model.rounded" :min="1" :max="1000"></multiplier>
		</div>
	</div>
</template>

<script>
	import MultipleRange from "../ui/multiple-range";
	import Multiplier from "../slot-config-editor/prize/multiplier";

	export default {
		name: "spinner-graph-controls",
		components: {Multiplier, MultipleRange},
		props: {
			value: {
				type: Object,
				default() {
					return {
						grouped: true,
						showAmount: false,
						groups: [50, 100, 500],
						rounded: 1
					}
				}
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			}
		}
	}
</script>

<style scoped>

</style>
