<template>
	<div v-if="config && symbol !== undefined">
		<div class="row my-2 d-flex align-items-center">
			<div class="col-auto">
				<symbol-icon :symbol="symbol"></symbol-icon>
			</div>
			<div class="col">
				<div class="row justify-content-center">
					<div class="col text-center">
						<div class="text-center d-inline-block">
							<div class="text-muted text-small">Occurrencies</div>
							<div>{{totalCombinations}}</div>
						</div>
					</div>
					<div class="col text-center">
						<div class="text-center d-inline-block">
							<div class="text-muted text-small">Rate</div>
							<div>1 in {{Math.round(1/(totalCombinations / totalSpins))}}</div>
						</div>
					</div>
					<div class="col text-center">
						<div class="text-center d-inline-block">
							<div class="text-muted text-small">EV</div>
							<div>{{(totalCombinations / totalSpins) | numeral("0.00%")}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col">
				<payline-combination-table v-model="model" :bet-per-line="betPerLine" :config="config" :symbol="symbol" :combinations="combination.combinations" :total-spins="totalSpins"></payline-combination-table>
			</div>
		</div>
	</div>
</template>
<script>
	import SymbolIcon from "../slot-config-editor/symbols/symbol-icon"
	import PaylineCombinationTable from "./payline-combination-table";

	export default {
		name: 'payline-combination-group',
		components: {PaylineCombinationTable, SymbolIcon},
		props: {
			value: Boolean,
			combination: Object,
			config: Object,
			totalSpins: Number,
			betPerLine: Number,
		},
		computed: {
			symbol() {
				return (this.config.symbols.slice().filter(s => s.id === this.combination.symbol)[0])
			},
			totalCombinations() {
				return Object.values(this.combination.combinations).reduce((c, combo) => c + combo, 0)
			},
			model: {
				get() {
					return this.value
				},
				set(_val) {
					this.$emit("input", _val)
				}
			},
		}
	}
</script>
