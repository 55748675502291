<template>
	<div>
		<div class="row d-flex justify-content-center">
			<div class="mx-lg-2">
				<fusioncharts
						type="column2d"
						:width="width"
						:height="height"
						:data-format="dataFormat"
						:data-source="datasource"
				>
				</fusioncharts>
				<spinner-graph-controls class="mt-2 mb-5" v-model="model"></spinner-graph-controls>
			</div>

			<div class="mx-lg-2">
				<fusioncharts
						type="pie2d"
						:width="width"
						:height="height"
						:data-format="dataFormat"
						:data-source="datasourceProbability"
				>
				</fusioncharts>

				<spinner-graph-controls class="mt-2 mb-5" v-model="model"></spinner-graph-controls>
			</div>
		</div>

		<div class="row">
			<div class="col">
				<table class="table text-small border">
					<thead>
					<tr class="font-weight-bold">
						<td>Prize</td>
						<td>Counter</td>
						<td>Amount</td>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(winning, winning_value) in groupedWinnings">
						<td v-text="winning_value"></td>
						<td>{{winning.c | numeral('0,0')}} ({{(winning.c / totalWinningsCounter) |
							numeral('0.000%')}})
						</td>
						<td>{{winning.a | numeral('0,0')}} ({{(winning.a / totalWinningsAmount) |
							numeral('0.000%')}})
						</td>
					</tr>
					</tbody>
					<tfoot>
					<tr>
						<td>TOTALS</td>
						<td>{{totalWinningsCounter | numeral('0,0')}}</td>
						<td>{{totalWinningsAmount | numeral('0,0')}}</td>
					</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue';
	import VueFusionCharts from 'vue-fusioncharts';
	import FusionCharts from 'fusioncharts';
	import Column2D from 'fusioncharts/fusioncharts.charts';
	import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
	import SpinnerGraphControls from "./spinner-graph-controls";

	Vue.use(VueFusionCharts, FusionCharts, Column2D, FusionTheme);
	export default {
		name: "spinner-winning-graph",
		components: {SpinnerGraphControls},
		props: {
			value: {
				type: Object,
				default: null
			},
			lines: {
				type: Number,
				default: 1
			},
			winnings: {
				type: Object,
				default() {
					return {}
				}
			}
		},
		data() {
			return {
				type: "column2d",
				renderAt: "chart-container",
				width: "550",
				height: "350",
				dataFormat: "json",
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(val) {
					this.$emit('input', val)
				}
			},
			key() {
				return this.model.showAmount ? "a" : "c"
			},
			displayValueName() {
				return this.model.showAmount ? "Amount" : "Count"
			},
			totalWinningsAmount() {
				return this.winnings.total
			},
			totalWinningsCounter() {
				return this.winnings.count
			},
			steps() {
				const max = 999999999999999
				let all_steps = [...this.model.groups, max]
				let last_step = 0

				return all_steps.map(s => {
					const ret = {
						name: `${last_step}-${s < max ? s : `∞`}`,
						min: last_step,
						max: s
					}

					last_step = s

					return ret
				})
			},
			roundedData() {

				if(this.model.rounded === 1){
					return this.winnings.wins
				}

				let data = {}

				for (let prize_value in this.winnings.wins) {
					const prize = this.winnings.wins[prize_value]
					const roundedValue = Math.ceil(parseInt(prize_value) / this.model.rounded) * this.model.rounded
					const key = `${roundedValue}`

					if (data[key] === undefined) {
						data[key] = {
							c: 0,
							a: 0
						}
					}

					data[key] = prize
					data[key].c += prize.c
					data[key].a += prize.a
				}

				return data
			},
			groupedWinnings() {
				if (this.model.grouped) {
					let new_winnings = {}

					for (let prize_value in this.winnings.wins) {
						const prize = this.winnings.wins[prize_value]
						const step = this.steps.find(s => parseInt(prize_value) >= s.min && parseInt(prize_value) < s.max)
						const key = step.name

						if (undefined === new_winnings[key]) {
							new_winnings[key] = {
								c: 0,
								a: 0
							}
						}

						new_winnings[key].c += prize.c
						new_winnings[key].a += prize.a
					}

					return new_winnings
				} else {
					return this.roundedData
				}
			},
			dataInput() {
				let data = []
				for (let winning_value in this.groupedWinnings) {
					data.push({
						"label": winning_value,
						"value": this.groupedWinnings[winning_value][this.key]
					})
				}
				return data
			},
			datasource() {

				return {
					"chart": {
						"caption": `${this.winnings.type} Prizes distribution ${this.displayValueName}`.toUpperCase(),
						"theme": "fusion",
						drawcrossline: true
					},
					"data": this.dataInput
				}
			},
			datasourceProbability() {
				return {
					"chart": {
						"caption": `${this.winnings.type} Prizes probability ${this.displayValueName}`.toUpperCase(),
						"theme": "fusion"
					},
					"data": this.dataInput
				}
			}
		},
	}
</script>

<style lang="scss">
	@import "@/assets/style/_palette";

	text[fill-opacity="0.5"][stroke="none"][x="6"][y="343"] {
		display: none;
		opacity: 0;
	}
</style>
