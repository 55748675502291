<template>
	<div>
		<ul class="nav nav-tabs my-3">
			<li class="nav-item" v-for="tab in tabs">
				<button class="nav-link text-small text-capitalize" :class="{active: currentTab === tab}"
								@click.prevent="currentTab = tab" v-text="tab">
				</button>
			</li>
		</ul>
		<spinner-winning-graph v-model="model" :winnings="currentData"></spinner-winning-graph>
	</div>
</template>

<script>
import SpinnerWinningGraph from "./spinner-winning-graph";

export default {
	name: "spinner-winnings",
	components: {SpinnerWinningGraph},
	props: {
		value: {
			type: Object,
			default: null
		},
		lines: {
			type: Number,
			default: 1
		},
		winnings: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			currentTab: 'global'
		}
	},
	mounted() {
		console.log('typeof this.winnings', typeof this.winnings)
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			}
		},
		currentData() {
			if (typeof this.winnings === 'object') {
				return this.winnings[this.currentTab]
			} else {
				return {}
			}
		},
		tabs() {
			return ['global', 'payline', 'freespin', 'bonus']
		}
	}
}
</script>

<style scoped>

</style>
